import SCSS from '../SVG.module.scss';

const Backdrop = () => {
	return (
		<g id='Backdrop' className={SCSS.Backdrop}>
			<polyline
				className={SCSS.Balcony}
				id='Balcony'
				points='311.03,12.96 354.3,12.96 378.47,207.55 371.84,225.58 354.3,231.94 215.65,218.1 
		215.65,177.05 311.03,177.05'
			/>
			<polyline
				className={SCSS.Interiors}
				id='Interior'
				points='0.25,6.59 55.38,6.59 55.38,32.53 107.58,32.53 107.58,11.4 311.03,11.4 311.03,177.05 
		215.65,177.05 215.65,218.1 0.25,218.1'
			/>
		</g>
	);
};

export default Backdrop;
