import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MasterBedroom: IScene = {
	name: 'Master Bedroom',
	pan: '-90deg',
	hotspot: {
		x: 230,
		y: 130,
	},
	images: {
		scheme1: Scheme1.MasterBedroom,
		scheme2: Scheme2.MasterBedroom,
	},
	default: {
		latitude: -0.05555844477338878,
		longitude: 1.5585660717191756,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3827327901632289,
				longitude: 5.818134906316363,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.030471238906042863,
					longitude: 6.277614021675901,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4244823104376305,
				longitude: 0.3226936420056786,
			},
			target: {
				name: 'Ensuite',
				view: {
					latitude: -0.059599026058633875,
					longitude: 6.276752622110167,
				},
			},
		},
	],
};

export default MasterBedroom;
