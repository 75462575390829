import SVG from '../SVG.module.scss';

const DoorSwings = () => {
	return (
		<g className={SVG.DoorSwings}>
			<path d='M22.94,37.74C37.33,37,48.62,25.13,48.63,10.73' />
			<path d='M213.31,115.5c13.59,0,24.8-10.64,25.51-24.21' />
			<path d='M83.61,125.14c0.71,13.57,11.92,24.21,25.51,24.21' />
			<rect x='220.14' y='210.6' width='17.59' height='0.75' />
			<path d='M242.22,210.61c-0.4-14.38-12.18-25.84-26.56-25.84' />
			<path d='M257.63,62.25c0-13.59-10.64-24.8-24.21-25.51' />
			<path d='M44.42,69.46c0,13.59,10.64,24.8,24.21,25.51' />
			<path d='M81.86,58.93c-0.37,1.73-0.56,3.49-0.56,5.26' />
			<path d='M112.6,116.39c1.52,0,3.04-0.15,4.54-0.46' />
		</g>
	);
};

export default DoorSwings;
