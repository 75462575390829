import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Living: IScene = {
	name: 'Living',
	pan: '180deg',
	hotspot: {
		x: 170,
		y: 190,
	},
	images: {
		scheme1: Scheme1.Living,
		scheme2: Scheme2.Living,
	},
	default: {
		latitude: -0.21814236575228296,
		longitude: 6.279654091462627,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.14206963892249513,
				longitude: 6.23952024943242,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.0784574712731998,
					longitude: 6.275800812791475,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.18319378963779842,
				longitude: 0.48924307196209876,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.039561650497684564,
					longitude: 0.058216653863306905,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.25797173271739426,
				longitude: 5.918962769901379,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.03235414678316362,
					longitude: 6.199036057137677,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2263010795991911,
				longitude: 5.499386554681263,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.04815501943752043,
					longitude: 6.27770915049999,
				},
			},
		},
	],
};

export default Living;
