import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MainBath: IScene = {
	name: 'Main Bath',
	pan: '90deg',
	hotspot: {
		x: 55,
		y: 85,
	},
	images: {
		scheme1: Scheme1.MainBath,
		scheme2: Scheme2.MainBath,
	},
	default: {
		latitude: -0.06243506459189252,
		longitude: 3.1512503251679678,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.82604365140332,
				longitude: 0.25807543196450355,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.06645980593371448,
					longitude: 0.5462813415297264,
				},
			},
		},
	],
};

export default MainBath;
