import SCSS from '../SVG.module.scss';

export const Walls = () => {
	return (
		<g className={SCSS.Walls}>
			<polygon
				points='21.61,6.59 21.61,10.73 3.5,10.73 3.5,64.2 44.42,64.2 44.42,69.46 27.5,69.46 27.5,71.4 
			20.95,71.4 20.95,69.46 9.13,69.46 9.13,71.4 3.5,71.4 3.5,116.4 74.39,116.4 74.39,69.46 69.96,69.46 69.96,64.2 81.33,64.2 
			81.33,67.74 79.68,67.74 79.68,86.35 109.12,86.35 109.12,67.74 106.87,67.74 106.87,64.26 112.6,64.26 112.6,93.89 109.12,93.89 
			109.12,91.6 77.88,91.6 77.88,118.18 109.12,118.18 109.12,116.39 112.6,116.39 112.6,123.82 109.12,123.82 109.12,121.66 
			3.5,121.66 3.5,212.25 35.71,212.25 35.71,214.72 3.5,214.72 0.25,214.7 0.25,6.59 20.14,6.59	'
			/>
			<polygon
				points='84.43,214.7 84.43,210.67 87.77,210.67 87.77,212.25 105.37,212.25 105.37,155.68 87.77,155.68 
			87.77,156.64 84.29,156.64 84.29,152.19 109.12,152.19 109.12,149.36 112.6,149.36 112.6,152.19 120.43,152.19 120.43,212.25 
			137,212.25 137,214.72 117.77,214.72 117.77,214.7 108.03,214.7 108.03,214.7	'
			/>
			<polygon
				points='209.83,184.77 212.28,184.77 212.28,173.68 215.64,173.68 215.64,171.23 213.31,171.23 
			213.31,115.5 209.83,115.5	'
			/>
			<polygon
				points='307.65,12.59 322.69,12.59 322.69,6.98 322.69,1.37 294.05,1.37 294.05,11.4 294.05,11.4 
			121.17,11.4 121.17,11.4 107.58,11.4 107.58,32.53 55.36,32.53 55.38,6.59 52.13,6.59 48.63,6.59 48.63,10.73 52.13,10.73 
			52.13,35.76 123.08,35.76 123.08,17.25 225.65,17.25 225.65,83.87 209.83,83.87 209.83,89.96 213.31,89.96 213.31,88.49 
			213.31,87.35 229.14,87.35 229.14,65.74 232.09,65.74 232.09,62.25 229.14,62.25 229.14,17.25 305.2,17.25 305.2,62.25 
			300.77,62.25 300.77,64.47 288.96,64.47 288.96,62.25 257.63,62.25 257.63,65.74 259.11,65.74 260.58,65.74 260.58,87.14 
			266.61,87.14 266.61,83.66 264.07,83.66 264.07,65.74 305.2,65.74 305.2,83.66 302.63,83.66 302.63,87.14 305.2,87.14 
			305.2,171.23 290.95,171.23 290.95,173.68 307.65,173.68	'
			/>
			<circle cx='326.68' cy='158.45' r='11.22' />
		</g>
	);
};
export default Walls;
