import SCSS from '../SVG.module.scss';

const index = () => {
	return (
		<g className={SCSS.Flooring}>
			<line x1='232.09' y1='63.96' x2='257.63' y2='63.99' />
			<line x1='69.96' y1='66.83' x2='44.42' y2='66.8' />
			<line x1='110.86' y1='116.39' x2='110.86' y2='93.89' />
			<line x1='215.65' y1='171.23' x2='290.95' y2='171.23' />
			<line x1='137' y1='212.25' x2='212.28' y2='212.25' />
			<line x1='84.29' y1='212.25' x2='35.71' y2='212.25' />
		</g>
	);
};

export default index;
