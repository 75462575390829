import SCSS from '../SVG.module.scss';

const Lineworks = () => {
	return (
		<g className={SCSS.Lineworks}>
			<rect x='290.2' y='173.68' width='1.5' height='3.38' />
			<rect x='291.7' y='173.68' width='15.2' height='3.38' />
			<rect x='307.65' y='146.21' width='3.38' height='26.72' />
			<rect x='307.65' y='144.71' width='3.38' height='1.5' />
			<rect x='307.65' y='119.64' width='3.38' height='25.07' />
			<rect x='307.65' y='118.14' width='3.38' height='1.5' />
			<rect x='307.65' y='93.06' width='3.38' height='25.07' />
			<rect x='307.65' y='91.56' width='3.38' height='1.5' />
			<rect x='307.65' y='66.49' width='3.38' height='25.07' />
			<rect x='307.65' y='64.99' width='3.38' height='1.5' />
			<rect x='307.65' y='39.91' width='3.38' height='25.07' />
			<rect x='307.65' y='38.41' width='3.38' height='1.5' />
			<rect x='307.65' y='13.34' width='3.38' height='25.07' />
			<rect x='307.65' y='12.59' width='3.38' height='0.75' />
			<rect x='324.15' y='11.27' width='0.47' height='2.62' />
			<rect x='324.62' y='12.21' width='26.31' height='0.75' />
			<rect x='350.93' y='11.27' width='0.47' height='2.62' />
			<line x1='351.4' y1='12.59' x2='354.3' y2='12.59' />
			<line x1='324.15' y1='12.59' x2='322.69' y2='12.59' />
			<polygon
				points='353.16,12.59 377.07,207.3 370.95,224.7 353.97,230.8 216.18,217.43 216.09,218.55 354.11,231.94 
			371.84,225.58 378.22,207.42 354.3,12.59	'
			/>
			<polygon points='306.9,173.68 307.65,173.68 307.65,172.93 311.03,172.93 311.03,177.05 306.9,177.05	' />
			<rect x='269.55' y='173.68' width='20.65' height='3.38' />
			<rect x='268.05' y='173.68' width='1.5' height='3.38' />
			<rect x='242.98' y='173.68' width='25.07' height='3.38' />
			<rect x='241.48' y='173.68' width='1.5' height='3.38' />
			<rect x='216.4' y='173.68' width='25.07' height='3.38' />
			<rect x='215.65' y='173.68' width='0.75' height='3.38' />
			<rect x='212.28' y='173.68' width='3.38' height='4.12' />
			<rect x='212.28' y='177.8' width='3.38' height='6.22' />
			<rect x='212.28' y='184.02' width='3.38' height='0.75' />
			<rect x='212.28' y='211.35' width='3.38' height='6.75' />
			<rect x='211.53' y='214.7' width='0.75' height='3.39' />
			<line x1='290.2' y1='175.37' x2='269.55' y2='175.37' />
			<line x1='268.05' y1='175.37' x2='242.98' y2='175.37' />
			<line x1='241.48' y1='175.37' x2='216.4' y2='175.37' />
			<rect x='186.47' y='214.7' width='25.05' height='3.39' />
			<rect x='184.97' y='214.7' width='1.5' height='3.39' />
			<rect x='160.47' y='214.72' width='24.5' height='3.38' />
			<rect x='158.97' y='214.7' width='1.5' height='3.39' />
			<rect x='138.33' y='214.7' width='20.65' height='3.39' />
			<rect x='136.83' y='214.7' width='1.5' height='3.39' />
			<rect x='111.75' y='214.7' width='25.07' height='3.39' />
			<rect x='110.25' y='214.7' width='1.5' height='3.39' />
			<rect x='84.8' y='214.72' width='25.45' height='3.38' />
			<rect x='83.68' y='214.72' width='1.12' height='3.38' />
			<rect x='58.6' y='214.72' width='25.07' height='3.38' />
			<rect x='57.1' y='214.72' width='1.5' height='3.38' />
			<rect x='34.96' y='214.72' width='1.5' height='3.38' />
			<rect x='9.88' y='214.72' width='25.07' height='3.38' />
			<rect x='8.38' y='214.72' width='1.5' height='3.38' />
			<rect x='0.25' y='214.7' width='8.13' height='3.4' />
			<line x1='83.68' y1='216.41' x2='58.6' y2='216.41' />
			<line x1='57.1' y1='216.41' x2='36.46' y2='216.41' />
			<line x1='138.33' y1='216.41' x2='158.97' y2='216.41' />
			<line x1='160.47' y1='216.4' x2='184.97' y2='216.4' />
			<line x1='186.47' y1='216.4' x2='211.53' y2='216.4' />
			<rect x='84.43' y='169.02' width='1.48' height='27.77' />
			<rect x='85.9' y='182.91' width='1.48' height='27.77' />
			<rect x='3.5' y='71.4' width='24' height='45' />
			<path
				d='M5.88,94.98l2.07,17.19c0.14,1.13,1.1,1.98,2.23,1.98h10.63c1.14,0,2.1-0.85,2.23-1.98l2.07-17.19
			c0.09-0.71,0.09-1.44,0-2.15l-2.07-17.19c-0.14-1.13-1.1-1.98-2.23-1.98H10.18c-1.14,0-2.1,0.85-2.23,1.98L5.88,92.83
			C5.79,93.54,5.79,94.27,5.88,94.98z'
			/>
			<line x1='71.63' y1='100.63' x2='71.63' y2='112.63' />
			<line x1='63.15' y1='109.87' x2='63.92' y2='109.87' />
			<path d='M64.1,111.18c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='63.15' y1='110.99' x2='63.92' y2='110.99' />
			<path d='M63.15,110.99c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='64.1' y1='110.06' x2='64.1' y2='114.28' />
			<path d='M64.1,110.06c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='63.15' y1='109.87' x2='63.92' y2='109.87' />
			<path d='M63.15,109.87c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='62.96' y1='114.28' x2='62.96' y2='110.06' />
			<path d='M63.96,114.43c0.08,0,0.14-0.06,0.14-0.14' />
			<line x1='63.11' y1='114.43' x2='63.96' y2='114.43' />
			<path d='M62.96,114.28c0,0.08,0.06,0.14,0.14,0.14' />
			<line x1='55.44' y1='112.63' x2='62.96' y2='112.63' />
			<line x1='55.44' y1='100.63' x2='55.44' y2='112.63' />
			<line x1='71.63' y1='100.63' x2='55.44' y2='100.63' />
			<line x1='64.1' y1='112.63' x2='71.63' y2='112.63' />
			<polyline points='27.5,110.61 51.5,110.61 51.5,99.53 74.39,99.53	' />
			<path
				d='M45.68,101.9c0-0.67-0.07-1.06-0.14-1.48c-0.09-0.54-0.2-1.12-0.4-1.65c-0.21-0.55-0.53-1.04-0.86-1.43
			c-0.25-0.3-0.5-0.55-0.73-0.73c-0.19-0.15-0.36-0.24-0.6-0.35c-0.23-0.1-0.51-0.2-0.8-0.28c-0.31-0.08-0.62-0.14-0.91-0.17
			c-0.3-0.03-0.57-0.03-0.91-0.03c-0.32,0-0.7,0-1.04,0.03c-0.32,0.03-0.61,0.08-0.87,0.12c-0.22,0.04-0.43,0.08-0.63,0.15
			c-0.21,0.08-0.41,0.19-0.61,0.32c-0.29,0.18-0.58,0.38-0.89,0.71c-0.33,0.35-0.69,0.84-0.92,1.26c-0.22,0.41-0.32,0.76-0.41,1.1
			c-0.08,0.29-0.15,0.56-0.23,1.04c-0.07,0.39-0.13,0.92-0.13,1.4'
			/>
			<line x1='34.62' y1='101.9' x2='34.62' y2='110.61' />
			<line x1='45.68' y1='101.9' x2='45.68' y2='110.61' />
			<rect x='21.61' y='10.73' width='1.33' height='27.02' />
			<rect x='283.87' y='84.19' width='18.76' height='1.48' />
			<rect x='274.49' y='85.67' width='18.76' height='1.48' />
			<line x1='237.07' y1='33.02' x2='237.07' y2='21.02' />
			<line x1='245.55' y1='23.78' x2='244.78' y2='23.78' />
			<path d='M244.6,22.48c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='245.55' y1='22.67' x2='244.78' y2='22.67' />
			<path d='M245.55,22.67c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='244.6' y1='23.6' x2='244.6' y2='19.37' />
			<path d='M244.6,23.6c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='245.55' y1='23.78' x2='244.78' y2='23.78' />
			<path d='M245.55,23.78c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='245.74' y1='19.37' x2='245.74' y2='23.6' />
			<path d='M244.74,19.23c-0.08,0-0.14,0.06-0.14,0.14' />
			<line x1='245.59' y1='19.23' x2='244.74' y2='19.23' />
			<path d='M245.74,19.37c0-0.08-0.06-0.14-0.14-0.14' />
			<line x1='253.26' y1='21.02' x2='245.74' y2='21.02' />
			<line x1='253.26' y1='33.02' x2='253.26' y2='21.02' />
			<line x1='237.07' y1='33.02' x2='253.26' y2='33.02' />
			<line x1='244.6' y1='21.02' x2='237.07' y2='21.02' />
			<polyline points='281.14,17.25 281.14,23.04 257.2,23.04 257.2,34.13 233.2,34.13 233.2,17.25	' />
			<path
				d='M263.02,31.76c0,0.67,0.07,1.06,0.14,1.48c0.09,0.54,0.2,1.12,0.4,1.65c0.21,0.55,0.53,1.04,0.86,1.43
			c0.25,0.3,0.5,0.55,0.73,0.73c0.19,0.15,0.36,0.24,0.6,0.35c0.23,0.1,0.51,0.2,0.8,0.28c0.31,0.08,0.62,0.14,0.91,0.17
			c0.3,0.03,0.57,0.03,0.91,0.03c0.32,0,0.7,0,1.04-0.03c0.32-0.03,0.61-0.08,0.87-0.12c0.22-0.04,0.43-0.08,0.63-0.15
			c0.21-0.08,0.41-0.19,0.61-0.32c0.29-0.18,0.58-0.38,0.89-0.71c0.33-0.35,0.69-0.84,0.92-1.26c0.22-0.41,0.32-0.76,0.41-1.1
			c0.08-0.29,0.15-0.56,0.23-1.04c0.07-0.39,0.13-0.92,0.13-1.4'
			/>
			<line x1='274.08' y1='31.76' x2='274.08' y2='23.04' />
			<line x1='263.02' y1='31.76' x2='263.02' y2='23.04' />
			<polyline points='209.83,83.87 207.65,83.87 207.65,35.25 123.08,35.25	' />
			<rect x='144.83' y='17.25' width='0.75' height='18' />
			<rect x='123.08' y='17.25' width='0.75' height='18' />
			<rect x='81.81' y='94.74' width='22.69' height='20.44' />
			<rect x='213.31' y='89.96' width='25.51' height='1.33' />
			<rect x='83.61' y='123.82' width='25.51' height='1.33' />
			<rect x='237.73' y='210.6' width='4.5' height='0.75' />
			<rect x='215.65' y='210.6' width='4.49' height='0.75' />
			<rect x='232.09' y='36.74' width='1.33' height='25.51' />
			<rect x='68.64' y='69.46' width='1.33' height='25.51' />

			<rect
				x='93.7'
				y='48.82'
				transform='matrix(0.2588 -0.9659 0.9659 0.2588 10.4462 136.8043)'
				width='1.33'
				height='25.54'
			/>

			<rect
				x='114.21'
				y='93.68'
				transform='matrix(0.9659 -0.2588 0.2588 0.9659 -23.2433 33.3065)'
				width='1.33'
				height='22.5'
			/>
			<rect x='36.46' y='214.72' width='20.65' height='3.38' />

			<rect
				x='280.76'
				y='38.78'
				transform='matrix(0.9659 -0.2588 0.2588 0.9659 -3.1923 74.4227)'
				width='0.59'
				height='21.11'
			/>
			<rect x='283.5' y='59.61' width='0.59' height='2.64' />
			<rect x='283.5' y='17.25' width='0.59' height='21.25' />
			<line x1='300.77' y1='62.25' x2='288.96' y2='62.25' />
			<line x1='212.65' y1='218.1' x2='216.1' y2='218.43' />
			<rect x='147.36' y='35.25' width='18' height='0.38' />
			<path d='M148.36,35.63c5.15,1.95,10.84,1.95,15.99,0' />
			<line x1='96.37' y1='212.25' x2='96.37' y2='155.68' />
			<line x1='79.68' y1='77.35' x2='109.12' y2='77.35' />
			<line x1='305.2' y1='76.15' x2='264.07' y2='76.15' />
			<rect x='167.92' y='18.75' width='21' height='15.75' />
			<path d='M178.84,19.88c0-0.23-0.19-0.42-0.42-0.42c-0.23,0-0.42,0.19-0.42,0.42' />
			<path
				d='M178.84,20.39c0.28-0.23,0.32-0.65,0.09-0.93s-0.65-0.32-0.93-0.09c-0.28,0.23-0.32,0.65-0.09,0.93
			c0.03,0.03,0.06,0.06,0.09,0.09'
			/>
			<polyline points='178.84,19.88 178.84,19.88 178.84,25.25	' />
			<line x1='178' y1='25.25' x2='178' y2='19.88' />
			<polyline points='180.47,19.22 180.47,20.55 178.84,20.55	' />
			<line x1='180.47' y1='19.22' x2='178.42' y2='19.22' />
			<path d='M178,25.25c0,0.23,0.19,0.42,0.42,0.42c0.23,0,0.42-0.19,0.42-0.42' />
			<rect x='207.28' y='42.34' width='0.38' height='18' />
			<path d='M207.28,43.35c-1.95,5.15-1.95,10.84,0,15.99' />
			<line x1='209.01' y1='40.09' x2='224.52' y2='40.09' />
			<line x1='209.01' y1='62.59' x2='224.52' y2='62.59' />
			<circle cx='210.31' cy='43.86' r='0.68' />
			<circle cx='210.31' cy='48.83' r='0.68' />
			<circle cx='210.31' cy='58.83' r='0.68' />
			<circle cx='210.31' cy='53.85' r='0.68' />
			<line x1='224.76' y1='62.36' x2='224.76' y2='40.33' />
			<line x1='208.78' y1='40.33' x2='208.78' y2='62.36' />
			<path d='M209.01,40.09c-0.13,0-0.23,0.1-0.23,0.23' />
			<path d='M208.78,62.36c0,0.13,0.1,0.23,0.23,0.23' />
			<path d='M224.52,62.59c0.13,0,0.23-0.1,0.23-0.23' />
			<path d='M224.76,40.33c0-0.13-0.1-0.23-0.23-0.23' />
		</g>
	);
};

export default Lineworks;
