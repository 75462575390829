import SCSS from '../SVG.module.scss';

const Dashed = () => {
	return (
		<g className={SCSS.Dashed}>
			<line x1='216.65' y1='83.87' x2='216.65' y2='82.37' />
			<line x1='216.65' y1='80.26' x2='216.65' y2='65.52' />
			<line x1='216.65' y1='64.47' x2='216.65' y2='62.97' />
			<line x1='225.65' y1='62.97' x2='224.15' y2='62.97' />
			<line x1='222.4' y1='62.97' x2='214.53' y2='62.97' />
			<polyline points='213.65,62.97 212.15,62.97 212.15,61.47' />
			<line x1='212.15' y1='59.63' x2='212.15' y2='42.14' />
			<polyline points='212.15,41.22 212.15,39.72 213.65,39.72' />
			<line x1='215.4' y1='39.72' x2='223.28' y2='39.72' />
			<line x1='224.15' y1='39.72' x2='225.65' y2='39.72' />
			<line x1='216.65' y1='39.72' x2='216.65' y2='38.22' />
			<line x1='216.65' y1='36.47' x2='216.65' y2='28.62' />
			<polyline points='216.65,27.75 216.65,26.25 215.15,26.25' />
			<line x1='213.12' y1='26.25' x2='148.1' y2='26.25' />
			<line x1='147.08' y1='26.25' x2='145.58' y2='26.25' />
			<line x1='165.36' y1='35.25' x2='165.36' y2='33.75' />
			<line x1='165.36' y1='31.38' x2='165.36' y2='20.69' />
			<polyline points='165.36,19.5 165.36,18 163.86,18' />
			<line x1='162.09' y1='18' x2='149.74' y2='18' />
			<polyline points='148.86,18 147.36,18 147.36,19.5' />
			<line x1='147.36' y1='21.88' x2='147.36' y2='32.56' />
			<line x1='147.36' y1='33.75' x2='147.36' y2='35.25' />
			<line x1='97.87' y1='212.25' x2='97.87' y2='210.75' />
			<line x1='97.87' y1='208.69' x2='97.87' y2='158.21' />
			<line x1='97.87' y1='157.18' x2='97.87' y2='155.68' />
			<line x1='79.68' y1='78.85' x2='81.18' y2='78.85' />
			<line x1='83.14' y1='78.85' x2='106.64' y2='78.85' />
			<line x1='107.62' y1='78.85' x2='109.12' y2='78.85' />
			<line x1='305.2' y1='74.65' x2='303.7' y2='74.65' />
			<line x1='301.64' y1='74.65' x2='266.6' y2='74.65' />
			<line x1='265.57' y1='74.65' x2='264.07' y2='74.65' />
		</g>
	);
};

export default Dashed;
