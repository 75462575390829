import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Ensuite: IScene = {
	name: 'Ensuite',
	pan: '0deg',
	hotspot: {
		x: 250,
		y: 45,
	},
	images: {
		scheme1: Scheme1.Ensuite,
		scheme2: Scheme2.Ensuite,
	},
	default: {
		latitude: -0.059599026058633875,
		longitude: 6.276752622110167,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4462013040148789,
				longitude: 3.3626210240871077,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.11954562187621232,
					longitude: 3.1312593818406333,
				},
			},
		},
	],
};

export default Ensuite;
