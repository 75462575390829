import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Kitchen: IScene = {
	name: 'Kitchen',
	pan: '180deg',
	hotspot: {
		x: 160,
		y: 45,
	},
	images: {
		scheme1: Scheme1.Kitchen,
		scheme2: Scheme2.Kitchen,
	},
	default: {
		latitude: -0.06645987556642163,
		longitude: 3.1365067304020022,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.39923535065792004,
				longitude: 2.4083595437363225,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.10354882760050055,
					longitude: 2.2568419014131007,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.20235880080927937,
				longitude: 3.0685953368457155,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.06118462328024599,
					longitude: 3.1421786329441628,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3564150426186039,
				longitude: 3.6465557967939524,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.06359822462756126,
					longitude: 3.7164079132324175,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.36523820638105287,
				longitude: 4.527567855026568,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.1055898096012835,
					longitude: 1.0158179665095242,
				},
			},
		},
	],
};

export default Kitchen;
