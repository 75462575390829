import SCSS from '../SVG.module.scss';

const Furniture = () => {
	return (
		<g className={SCSS.Furniture}>
			<rect x='141.29' y='66.38' width='35.62' height='22.5' />
			<rect x='144.85' y='88.88' width='12' height='6.15' />
			<line x1='156.85' y1='94.06' x2='144.85' y2='94.06' />
			<rect x='161.35' y='88.88' width='12' height='6.15' />
			<line x1='161.35' y1='94.06' x2='173.35' y2='94.06' />
			<rect x='144.85' y='60.23' width='12' height='6.15' />
			<line x1='156.85' y1='61.21' x2='144.85' y2='61.21' />
			<rect x='161.35' y='60.23' width='12' height='6.15' />
			<line x1='161.35' y1='61.21' x2='173.35' y2='61.21' />
			<rect x='160.75' y='166.36' width='22.5' height='22.5' />
			<rect x='120.43' y='146.98' width='24' height='65.27' />
			<polyline points='142.18,212.25 142.18,209.25 123.43,209.25 123.43,149.98 142.18,149.98 142.18,146.98	' />
			<line x1='144.43' y1='169.96' x2='123.43' y2='170.02' />
			<line x1='144.43' y1='189.28' x2='123.43' y2='189.28' />
			<rect x='124.04' y='150.65' width='4.5' height='57.93' />
			<rect x='199.73' y='139.73' width='9' height='45' />
			<rect x='203.51' y='144.23' width='1.12' height='36' />
			<polyline points='204.63,159.23 206.32,159.23 206.32,165.23 204.63,165.23	' />
			<polyline points='203.51,165.23 201.82,165.23 201.82,159.23 203.51,159.23	' />
			<rect x='154.84' y='121.16' width='25.98' height='24' />
			<polyline points='180.82,142.91 177.82,142.91 177.82,124.16 157.85,124.16 157.85,142.91 154.84,142.91	' />
			<rect x='158.51' y='124.77' width='18.64' height='4.5' />
			<rect x='5.24' y='140.92' width='0.75' height='45.75' />
			<polyline points='19.84,186.3 65.24,186.3 65.24,141.3 37.18,141.3	' />
			<polyline points='23.16,141.3 5.99,141.3 5.99,186.3 13.82,186.3	' />
			<rect x='5.24' y='187.59' width='13.29' height='13.29' />
			<rect x='5.24' y='126.72' width='13.29' height='13.29' />
			<polyline points='14.52,182.92 7.42,182.92 7.42,165.3 18.18,165.3	' />
			<polyline points='19.27,160.06 19.27,144.67 7.42,144.67 7.42,161.92 18.88,161.92	' />
			<polygon points='13.74,186.67 19.7,186.67 37.32,140.92 23.24,140.92	' />
			<rect x='304.45' y='108.17' width='0.75' height='45.75' />
			<polyline points='290.6,153.55 245.2,153.55 245.2,108.55 273.26,108.55	' />
			<polyline points='287.28,108.55 304.45,108.55 304.45,153.55 296.62,153.55	' />
			<rect x='291.91' y='93.97' width='13.29' height='13.29' />
			<rect x='291.91' y='154.84' width='13.29' height='13.29' />
			<polyline points='295.92,150.17 303.03,150.17 303.03,132.55 292.26,132.55	' />
			<polyline points='291.18,127.31 291.18,111.92 303.03,111.92 303.03,129.17 291.56,129.17	' />
			<polygon points='296.7,153.92 290.75,153.92 273.12,108.17 287.2,108.17	' />
		</g>
	);
};

export default Furniture;
