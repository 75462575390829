import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bedroom: IScene = {
	name: 'Bedroom',
	pan: '90deg',
	hotspot: {
		x: 70,
		y: 185,
	},
	images: {
		scheme1: Scheme1.Bedroom,
		scheme2: Scheme2.Bedroom,
	},
	default: {
		latitude: -0.09759141246343361,
		longitude: 4.73394048488101,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5656077108879414,
				longitude: 0.479001607217795,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.044464283437328334,
					longitude: 0.6516513004100948,
				},
			},
		},
	],
};

export default Bedroom;
