import SCSS from '../SVG.module.scss';

const ThinLineworks = () => {
	return (
		<g className={SCSS.ThinLineworks}>
			<circle cx='15.5' cy='82.56' r='0.75' />
			<line x1='71.07' y1='101.27' x2='71.07' y2='112.07' />
			<path
				d='M64.1,110.34c0.34-0.31,0.36-0.84,0.04-1.18c-0.31-0.34-0.84-0.36-1.18-0.04c-0.34,0.31-0.36,0.84-0.04,1.18
			c0.01,0.01,0.03,0.03,0.04,0.04'
			/>
			<path d='M64.28,113.29c0-0.1-0.08-0.18-0.18-0.19' />
			<line x1='64.28' y1='114.42' x2='64.28' y2='113.29' />
			<path d='M64.1,114.61c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='63.68' y1='114.61' x2='64.1' y2='114.61' />
			<line x1='62.97' y1='114.61' x2='63.39' y2='114.61' />
			<path d='M62.78,114.42c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='62.78' y1='113.29' x2='62.78' y2='114.42' />
			<path d='M62.96,113.11c-0.1,0-0.18,0.09-0.18,0.19' />
			<line x1='63.39' y1='114.98' x2='63.68' y2='114.98' />
			<line x1='63.39' y1='114.43' x2='63.39' y2='114.98' />
			<line x1='63.68' y1='114.98' x2='63.68' y2='114.43' />
			<line x1='56' y1='112.07' x2='62.96' y2='112.07' />
			<line x1='56' y1='101.27' x2='56' y2='112.07' />
			<line x1='71.07' y1='101.27' x2='56' y2='101.27' />
			<line x1='64.1' y1='112.07' x2='71.07' y2='112.07' />
			<path
				d='M45.31,107.99c-0.03,0.41-0.07,0.57-0.11,0.71c-0.04,0.13-0.08,0.24-0.14,0.35
			c-0.06,0.11-0.12,0.23-0.19,0.32c-0.07,0.1-0.13,0.18-0.2,0.25c-0.08,0.08-0.16,0.15-0.26,0.22c-0.12,0.08-0.26,0.17-0.4,0.24
			c-0.16,0.08-0.32,0.14-0.47,0.19c-0.16,0.05-0.31,0.09-0.47,0.12c-0.18,0.04-0.38,0.07-0.57,0.09c-0.24,0.03-0.47,0.05-0.71,0.06
			c-0.31,0.02-0.62,0.03-0.97,0.03c-0.43,0.01-0.91,0.01-1.41-0.01c-0.47-0.01-0.95-0.04-1.34-0.08c-0.32-0.03-0.56-0.07-0.85-0.14
			c-0.19-0.05-0.4-0.11-0.57-0.18c-0.15-0.05-0.27-0.11-0.39-0.17c-0.11-0.06-0.21-0.13-0.33-0.23c-0.12-0.1-0.25-0.23-0.35-0.36
			c-0.1-0.12-0.18-0.24-0.24-0.35c-0.06-0.09-0.11-0.18-0.15-0.28c-0.04-0.09-0.07-0.19-0.09-0.3c-0.02-0.1-0.04-0.19-0.06-0.31
			c-0.02-0.12-0.04-0.27-0.04-0.65'
			/>
			<path
				d='M45.31,101.9c0-0.3-0.01-0.53-0.04-0.74c-0.01-0.1-0.02-0.2-0.04-0.3c-0.01-0.09-0.03-0.19-0.05-0.28
			s-0.03-0.19-0.05-0.29c-0.02-0.1-0.04-0.2-0.05-0.29c-0.02-0.1-0.04-0.2-0.06-0.29c-0.02-0.1-0.04-0.19-0.07-0.29
			s-0.05-0.19-0.08-0.28c-0.03-0.09-0.06-0.18-0.09-0.27c-0.03-0.09-0.07-0.17-0.11-0.25c-0.04-0.08-0.08-0.16-0.12-0.24
			c-0.09-0.16-0.18-0.31-0.28-0.45c-0.1-0.14-0.21-0.28-0.31-0.4c-0.11-0.13-0.21-0.24-0.31-0.34c-0.1-0.1-0.2-0.19-0.29-0.26
			c-0.05-0.04-0.09-0.07-0.13-0.1c-0.04-0.03-0.09-0.06-0.13-0.08c-0.05-0.03-0.09-0.05-0.15-0.08c-0.05-0.03-0.11-0.05-0.18-0.08
			c-0.07-0.03-0.14-0.06-0.21-0.08c-0.07-0.03-0.15-0.05-0.23-0.08c-0.08-0.03-0.16-0.05-0.24-0.07s-0.16-0.04-0.24-0.06
			c-0.16-0.04-0.32-0.06-0.47-0.08c-0.08-0.01-0.15-0.02-0.22-0.02s-0.14-0.01-0.22-0.01c-0.14-0.01-0.29-0.01-0.46-0.01
			c-0.17,0-0.35,0-0.54,0c-0.19,0-0.38,0.01-0.55,0.02c-0.09,0.01-0.17,0.01-0.25,0.02s-0.16,0.02-0.24,0.03
			c-0.08,0.01-0.15,0.02-0.23,0.04c-0.04,0.01-0.07,0.01-0.11,0.02s-0.07,0.01-0.11,0.02c-0.07,0.01-0.14,0.03-0.2,0.04
			s-0.12,0.03-0.18,0.04c-0.03,0.01-0.05,0.02-0.08,0.02c-0.03,0.01-0.05,0.02-0.08,0.03c-0.05,0.02-0.1,0.04-0.16,0.07
			s-0.11,0.06-0.17,0.09c-0.06,0.03-0.12,0.07-0.17,0.11c-0.06,0.04-0.12,0.07-0.18,0.11c-0.06,0.04-0.12,0.08-0.17,0.12
			s-0.11,0.09-0.17,0.14c-0.06,0.05-0.12,0.1-0.18,0.16c-0.06,0.06-0.12,0.12-0.18,0.19s-0.12,0.14-0.19,0.22
			c-0.12,0.15-0.25,0.32-0.36,0.49c-0.06,0.08-0.11,0.17-0.16,0.25c-0.05,0.08-0.09,0.16-0.13,0.23c-0.04,0.08-0.07,0.15-0.1,0.22
			c-0.03,0.07-0.06,0.14-0.08,0.21c-0.02,0.07-0.05,0.14-0.07,0.2s-0.04,0.14-0.06,0.2s-0.04,0.14-0.06,0.21s-0.04,0.14-0.05,0.21
			c-0.02,0.07-0.04,0.15-0.05,0.23c-0.02,0.08-0.04,0.17-0.05,0.27c-0.01,0.05-0.02,0.1-0.03,0.16s-0.02,0.11-0.03,0.17
			c-0.02,0.12-0.04,0.25-0.05,0.39c-0.02,0.14-0.03,0.28-0.04,0.42c-0.01,0.14-0.02,0.28-0.02,0.41'
			/>
			<line x1='45.31' y1='101.9' x2='45.31' y2='107.99' />
			<line x1='34.99' y1='101.9' x2='34.99' y2='107.52' />
			<rect x='21.61' y='6.59' width='27.02' height='4.13' />
			<line x1='237.63' y1='32.39' x2='237.63' y2='21.59' />
			<path
				d='M244.6,23.32c-0.34,0.31-0.36,0.84-0.04,1.18c0.31,0.34,0.84,0.36,1.18,0.04c0.34-0.31,0.36-0.84,0.04-1.18
			c-0.01-0.01-0.03-0.03-0.04-0.04'
			/>
			<path d='M244.42,20.36c0,0.1,0.08,0.18,0.18,0.19' />
			<line x1='244.42' y1='19.24' x2='244.42' y2='20.36' />
			<path d='M244.6,19.05c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='245.02' y1='19.05' x2='244.6' y2='19.05' />
			<line x1='245.73' y1='19.05' x2='245.31' y2='19.05' />
			<path d='M245.92,19.24c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='245.92' y1='20.36' x2='245.92' y2='19.24' />
			<path d='M245.74,20.55c0.1,0,0.18-0.09,0.18-0.19' />
			<line x1='245.31' y1='18.67' x2='245.02' y2='18.67' />
			<line x1='245.31' y1='19.23' x2='245.31' y2='18.67' />
			<line x1='245.02' y1='18.67' x2='245.02' y2='19.23' />
			<line x1='252.7' y1='21.59' x2='245.74' y2='21.59' />
			<line x1='252.7' y1='32.39' x2='252.7' y2='21.59' />
			<line x1='237.63' y1='32.39' x2='252.7' y2='32.39' />
			<line x1='244.6' y1='21.59' x2='237.63' y2='21.59' />
			<path
				d='M263.39,25.66c0.03-0.41,0.07-0.57,0.11-0.71c0.04-0.13,0.08-0.24,0.14-0.35c0.06-0.11,0.12-0.23,0.19-0.32
			c0.07-0.1,0.13-0.18,0.2-0.25c0.08-0.08,0.16-0.15,0.26-0.22c0.12-0.08,0.26-0.17,0.4-0.24c0.16-0.08,0.32-0.14,0.47-0.19
			c0.16-0.05,0.31-0.09,0.47-0.12c0.18-0.04,0.38-0.07,0.57-0.09c0.24-0.03,0.47-0.05,0.71-0.06c0.31-0.02,0.62-0.03,0.97-0.03
			c0.43-0.01,0.91-0.01,1.41,0.01c0.47,0.01,0.95,0.04,1.34,0.08c0.32,0.03,0.56,0.07,0.85,0.14c0.19,0.05,0.4,0.11,0.57,0.18
			c0.15,0.05,0.27,0.11,0.39,0.17c0.11,0.06,0.21,0.13,0.33,0.23c0.12,0.1,0.25,0.23,0.35,0.36c0.1,0.12,0.18,0.24,0.24,0.35
			c0.06,0.09,0.11,0.18,0.15,0.28c0.04,0.09,0.07,0.19,0.09,0.3c0.02,0.1,0.04,0.19,0.06,0.31c0.02,0.12,0.04,0.27,0.04,0.65'
			/>
			<path
				d='M263.39,31.76c0,0.3,0.01,0.53,0.04,0.74c0.01,0.1,0.02,0.2,0.04,0.3s0.03,0.19,0.05,0.28
			c0.02,0.1,0.03,0.19,0.05,0.29s0.04,0.2,0.05,0.29c0.02,0.1,0.04,0.2,0.06,0.29c0.02,0.1,0.04,0.19,0.07,0.29
			c0.02,0.09,0.05,0.19,0.08,0.28c0.03,0.09,0.06,0.18,0.09,0.27c0.03,0.09,0.07,0.17,0.11,0.25s0.08,0.16,0.12,0.24
			c0.09,0.16,0.18,0.31,0.28,0.45s0.21,0.28,0.31,0.4s0.21,0.24,0.31,0.34c0.1,0.1,0.2,0.19,0.29,0.26c0.05,0.04,0.09,0.07,0.13,0.1
			s0.09,0.06,0.13,0.08c0.05,0.03,0.09,0.05,0.15,0.08c0.05,0.03,0.11,0.05,0.18,0.08c0.07,0.03,0.14,0.06,0.21,0.08
			c0.07,0.03,0.15,0.05,0.23,0.08c0.08,0.03,0.16,0.05,0.24,0.07c0.08,0.02,0.16,0.04,0.24,0.06c0.16,0.04,0.32,0.06,0.47,0.08
			c0.08,0.01,0.15,0.02,0.22,0.02c0.07,0.01,0.14,0.01,0.22,0.01c0.14,0.01,0.29,0.01,0.46,0.01c0.17,0,0.35,0,0.54,0
			c0.19,0,0.38-0.01,0.55-0.02c0.09-0.01,0.17-0.01,0.25-0.02c0.08-0.01,0.16-0.02,0.24-0.03c0.08-0.01,0.15-0.02,0.23-0.04
			c0.04-0.01,0.07-0.01,0.11-0.02s0.07-0.01,0.11-0.02c0.07-0.01,0.14-0.03,0.2-0.04c0.06-0.01,0.12-0.03,0.18-0.04
			c0.03-0.01,0.05-0.02,0.08-0.02c0.03-0.01,0.05-0.02,0.08-0.03c0.05-0.02,0.1-0.04,0.16-0.07c0.05-0.03,0.11-0.06,0.17-0.09
			s0.12-0.07,0.17-0.11c0.06-0.04,0.12-0.07,0.18-0.11c0.06-0.04,0.12-0.08,0.17-0.12c0.06-0.04,0.11-0.09,0.17-0.14
			s0.12-0.1,0.18-0.16c0.06-0.06,0.12-0.12,0.18-0.19s0.12-0.14,0.19-0.22c0.12-0.15,0.25-0.32,0.36-0.49
			c0.06-0.08,0.11-0.17,0.16-0.25c0.05-0.08,0.09-0.16,0.13-0.23c0.04-0.08,0.07-0.15,0.1-0.22s0.06-0.14,0.08-0.21
			s0.05-0.14,0.07-0.2c0.02-0.07,0.04-0.14,0.06-0.2c0.02-0.07,0.04-0.14,0.06-0.21c0.02-0.07,0.04-0.14,0.05-0.21
			c0.02-0.07,0.04-0.15,0.05-0.23c0.02-0.08,0.04-0.17,0.05-0.27c0.01-0.05,0.02-0.1,0.03-0.16c0.01-0.05,0.02-0.11,0.03-0.17
			c0.02-0.12,0.04-0.25,0.05-0.39c0.02-0.14,0.03-0.28,0.04-0.42c0.01-0.14,0.02-0.28,0.02-0.41'
			/>
			<line x1='263.39' y1='31.76' x2='263.39' y2='25.66' />
			<line x1='273.71' y1='31.76' x2='273.71' y2='26.13' />
			<line x1='123.8' y1='33.38' x2='144.83' y2='33.38' />
			<line x1='101.97' y1='115.18' x2='101.97' y2='94.74' />
			<line x1='212.28' y1='211.35' x2='212.28' y2='184.77' />
			<line x1='215.65' y1='211.35' x2='215.65' y2='184.77' />
			<rect x='284.09' y='17.62' width='20.74' height='44.29' />
			<line x1='284.09' y1='17.62' x2='293.8' y2='31.52' />
			<line x1='295.47' y1='31.51' x2='304.83' y2='17.62' />
			<line x1='295.13' y1='34.13' x2='304.83' y2='61.91' />
			<line x1='284.09' y1='61.91' x2='294.14' y2='34.12' />
			<circle cx='294.65' cy='32.73' r='1.48' />
			<line x1='281.14' y1='62.25' x2='281.14' y2='50.79' />
			<line x1='281.14' y1='48.51' x2='281.14' y2='23.04' />
			<path d='M150.13,35.63c4.07,1.2,8.39,1.2,12.46,0' />
			<circle cx='180.2' cy='19.88' r='0.19' />
			<line x1='179.62' y1='19.22' x2='179.62' y2='20.55' />
			<polyline points='178,21.75 169.42,21.75 169.42,33 187.42,33 187.42,21.75 178.84,21.75	' />
			<path
				d='M178.13,25.56c-0.38,0.16-0.56,0.6-0.41,0.98c0.16,0.38,0.6,0.56,0.98,0.41s0.56-0.6,0.41-0.98
			c-0.08-0.18-0.22-0.33-0.41-0.41'
			/>
			<path d='M207.28,45.11c-1.2,4.07-1.2,8.39,0,12.46' />
			<path
				d='M209.9,44.06c0.11,0.22,0.38,0.31,0.6,0.2s0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='209.66' y1='43.65' x2='210.71' y2='43.65' />
			<line x1='209.66' y1='44.06' x2='210.71' y2='44.06' />
			<path
				d='M209.9,49.03c0.11,0.22,0.38,0.31,0.6,0.2s0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='209.66' y1='48.63' x2='210.71' y2='48.63' />
			<line x1='209.66' y1='49.03' x2='210.71' y2='49.03' />
			<path
				d='M209.9,59.03c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='209.66' y1='59.03' x2='210.71' y2='59.03' />
			<line x1='209.66' y1='58.63' x2='210.71' y2='58.63' />
			<path
				d='M209.9,54.05c0.11,0.22,0.38,0.31,0.6,0.2s0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='209.66' y1='54.05' x2='210.71' y2='54.05' />
			<line x1='209.66' y1='53.65' x2='210.71' y2='53.65' />
			<line x1='212.02' y1='62.59' x2='212.02' y2='40.09' />
			<line x1='212.55' y1='62.59' x2='212.55' y2='57.28' />
			<line x1='212.55' y1='57.04' x2='212.55' y2='40.09' />
			<line x1='224.23' y1='62.59' x2='224.23' y2='57.28' />
			<line x1='224.23' y1='57.04' x2='224.23' y2='45.65' />
			<line x1='224.23' y1='45.41' x2='224.23' y2='40.09' />
			<line x1='213.12' y1='62.59' x2='213.12' y2='57.28' />
			<line x1='213.12' y1='57.04' x2='213.12' y2='45.65' />
			<line x1='213.12' y1='45.41' x2='213.12' y2='40.09' />
			<line x1='213.4' y1='62.59' x2='213.4' y2='57.28' />
			<line x1='213.4' y1='57.04' x2='213.4' y2='45.65' />
			<line x1='213.4' y1='45.41' x2='213.4' y2='40.09' />
			<line x1='223.1' y1='55.78' x2='223.1' y2='46.91' />
			<line x1='223.1' y1='44.15' x2='223.1' y2='40.09' />
			<line x1='223.1' y1='58.54' x2='223.1' y2='62.59' />
			<line x1='223.39' y1='55.78' x2='223.39' y2='46.9' />
			<line x1='223.39' y1='44.16' x2='223.39' y2='40.09' />
			<line x1='223.39' y1='58.53' x2='223.39' y2='62.59' />
			<line x1='221.67' y1='55.68' x2='221.67' y2='47.01' />
			<line x1='221.67' y1='44.05' x2='221.67' y2='40.09' />
			<line x1='221.67' y1='58.64' x2='221.67' y2='62.59' />
			<line x1='221.95' y1='55.68' x2='221.95' y2='47.01' />
			<line x1='221.95' y1='44.05' x2='221.95' y2='40.09' />
			<line x1='221.95' y1='58.64' x2='221.95' y2='62.59' />
			<line x1='220.52' y1='55.77' x2='220.52' y2='46.92' />
			<line x1='220.52' y1='44.14' x2='220.52' y2='40.09' />
			<line x1='220.52' y1='58.55' x2='220.52' y2='62.59' />
			<line x1='220.24' y1='55.77' x2='220.24' y2='46.91' />
			<line x1='220.24' y1='44.14' x2='220.24' y2='40.09' />
			<line x1='220.24' y1='58.54' x2='220.24' y2='62.59' />
			<line x1='217.36' y1='55.78' x2='217.36' y2='46.91' />
			<line x1='217.36' y1='44.15' x2='217.36' y2='40.09' />
			<line x1='217.36' y1='58.54' x2='217.36' y2='62.59' />
			<line x1='217.64' y1='55.78' x2='217.64' y2='46.9' />
			<line x1='217.64' y1='44.16' x2='217.64' y2='40.09' />
			<line x1='217.64' y1='58.53' x2='217.64' y2='62.59' />
			<line x1='215.92' y1='55.68' x2='215.92' y2='47.01' />
			<line x1='215.92' y1='44.05' x2='215.92' y2='40.09' />
			<line x1='215.92' y1='58.64' x2='215.92' y2='62.59' />
			<line x1='216.21' y1='55.68' x2='216.21' y2='47.01' />
			<line x1='216.21' y1='44.05' x2='216.21' y2='40.09' />
			<line x1='216.21' y1='58.64' x2='216.21' y2='62.59' />
			<line x1='214.78' y1='55.77' x2='214.78' y2='46.92' />
			<line x1='214.78' y1='44.14' x2='214.78' y2='40.09' />
			<line x1='214.78' y1='58.55' x2='214.78' y2='62.59' />
			<line x1='214.49' y1='55.77' x2='214.49' y2='46.91' />
			<line x1='214.49' y1='44.14' x2='214.49' y2='40.09' />
			<line x1='214.49' y1='58.54' x2='214.49' y2='62.59' />
			<line x1='219.02' y1='62.59' x2='219.02' y2='57.28' />
			<line x1='219.02' y1='57.04' x2='219.02' y2='45.65' />
			<line x1='219.02' y1='45.41' x2='219.02' y2='40.09' />
			<line x1='218.74' y1='62.59' x2='218.74' y2='57.28' />
			<line x1='218.74' y1='57.04' x2='218.74' y2='45.65' />
			<line x1='218.74' y1='45.41' x2='218.74' y2='40.09' />
			<line x1='212.02' y1='51.34' x2='224.76' y2='51.34' />
			<line x1='212.55' y1='51.77' x2='213.12' y2='51.77' />
			<line x1='213.4' y1='51.77' x2='214.49' y2='51.77' />
			<line x1='214.78' y1='51.77' x2='215.92' y2='51.77' />
			<line x1='216.21' y1='51.77' x2='217.36' y2='51.77' />
			<line x1='217.64' y1='51.77' x2='218.74' y2='51.77' />
			<line x1='219.02' y1='51.77' x2='220.24' y2='51.77' />
			<line x1='220.52' y1='51.77' x2='221.67' y2='51.77' />
			<line x1='221.95' y1='51.77' x2='223.1' y2='51.77' />
			<line x1='223.39' y1='51.77' x2='224.23' y2='51.77' />
			<line x1='212.55' y1='50.91' x2='213.12' y2='50.91' />
			<line x1='213.4' y1='50.91' x2='214.49' y2='50.91' />
			<line x1='214.78' y1='50.91' x2='215.92' y2='50.91' />
			<line x1='216.21' y1='50.91' x2='217.36' y2='50.91' />
			<line x1='217.64' y1='50.91' x2='218.74' y2='50.91' />
			<line x1='219.02' y1='50.91' x2='220.24' y2='50.91' />
			<line x1='220.52' y1='50.91' x2='221.67' y2='50.91' />
			<line x1='221.95' y1='50.91' x2='223.1' y2='50.91' />
			<line x1='223.39' y1='50.91' x2='224.23' y2='50.91' />
			<line x1='224.23' y1='62.16' x2='223.39' y2='62.16' />
			<line x1='223.1' y1='62.16' x2='221.95' y2='62.16' />
			<line x1='221.67' y1='62.16' x2='220.52' y2='62.16' />
			<line x1='220.24' y1='62.16' x2='219.02' y2='62.16' />
			<line x1='218.74' y1='62.16' x2='217.64' y2='62.16' />
			<line x1='217.36' y1='62.16' x2='216.21' y2='62.16' />
			<line x1='215.92' y1='62.16' x2='214.78' y2='62.16' />
			<line x1='214.49' y1='62.16' x2='213.4' y2='62.16' />
			<line x1='213.12' y1='62.16' x2='212.55' y2='62.16' />
			<line x1='212.55' y1='40.53' x2='213.12' y2='40.53' />
			<line x1='213.4' y1='40.53' x2='214.49' y2='40.53' />
			<line x1='214.78' y1='40.53' x2='215.92' y2='40.53' />
			<line x1='216.21' y1='40.53' x2='217.36' y2='40.53' />
			<line x1='217.64' y1='40.53' x2='218.74' y2='40.53' />
			<line x1='219.02' y1='40.53' x2='220.24' y2='40.53' />
			<line x1='220.52' y1='40.53' x2='221.67' y2='40.53' />
			<line x1='221.95' y1='40.53' x2='223.1' y2='40.53' />
			<line x1='223.39' y1='40.53' x2='224.23' y2='40.53' />
			<line x1='224.23' y1='45.65' x2='223.29' y2='45.65' />
			<line x1='220.33' y1='45.65' x2='219.02' y2='45.65' />
			<line x1='218.74' y1='45.65' x2='217.55' y2='45.65' />
			<line x1='214.59' y1='45.65' x2='213.4' y2='45.65' />
			<line x1='213.12' y1='45.65' x2='212.55' y2='45.65' />
			<line x1='224.23' y1='45.41' x2='223.29' y2='45.41' />
			<line x1='220.33' y1='45.41' x2='219.02' y2='45.41' />
			<line x1='218.74' y1='45.41' x2='217.55' y2='45.41' />
			<line x1='214.59' y1='45.41' x2='213.4' y2='45.41' />
			<line x1='213.12' y1='45.41' x2='212.55' y2='45.41' />
			<line x1='214.59' y1='57.04' x2='213.4' y2='57.04' />
			<line x1='213.12' y1='57.04' x2='212.55' y2='57.04' />
			<line x1='217.55' y1='57.04' x2='218.74' y2='57.04' />
			<line x1='219.02' y1='57.04' x2='220.33' y2='57.04' />
			<line x1='223.29' y1='57.04' x2='224.23' y2='57.04' />
			<line x1='214.59' y1='57.28' x2='213.4' y2='57.28' />
			<line x1='213.12' y1='57.28' x2='212.55' y2='57.28' />
			<line x1='217.55' y1='57.28' x2='218.74' y2='57.28' />
			<line x1='219.02' y1='57.28' x2='220.33' y2='57.28' />
			<line x1='223.29' y1='57.28' x2='224.23' y2='57.28' />
			<circle cx='221.81' cy='45.53' r='0.86' />
			<path d='M222.75,46.29c0.36-0.44,0.36-1.08,0-1.52' />
			<path d='M222.61,44.62c-0.45-0.4-1.13-0.4-1.59-0.01' />
			<path d='M220.88,44.76c-0.36,0.45-0.36,1.09,0,1.53' />
			<path d='M221.02,46.44c0.46,0.4,1.14,0.39,1.59-0.01' />
			<circle cx='221.81' cy='57.16' r='0.75' />
			<path d='M221.06,58.03c0.44,0.38,1.08,0.37,1.52-0.01' />
			<path d='M222.71,57.88c0.34-0.42,0.34-1.02,0-1.45' />
			<path d='M222.57,56.29c-0.43-0.38-1.08-0.38-1.52-0.01' />
			<path d='M220.91,56.43c-0.35,0.43-0.35,1.03,0,1.46' />
			<path d='M214.99,56.25c-0.44,0.52-0.44,1.29,0,1.81' />
			<path d='M215.13,58.21c0.54,0.48,1.35,0.48,1.88-0.01' />
			<path d='M217.15,58.06c0.43-0.52,0.43-1.28,0-1.8' />
			<path d='M217.01,56.11c-0.53-0.48-1.35-0.48-1.88-0.01' />
			<path d='M214.27,57.28c0.03,0.39,0.18,0.76,0.44,1.06' />
			<path d='M214.84,58.48c0.3,0.27,0.68,0.44,1.08,0.47' />
			<path d='M216.21,58.96c0.41-0.03,0.79-0.2,1.09-0.48' />
			<path d='M217.44,58.33c0.25-0.3,0.41-0.67,0.43-1.06' />
			<path d='M217.87,57.04c-0.03-0.39-0.18-0.76-0.43-1.06' />
			<path d='M217.3,55.83c-0.3-0.28-0.68-0.45-1.09-0.48' />
			<path d='M215.92,55.36c-0.4,0.03-0.78,0.2-1.08,0.47' />
			<path d='M214.7,55.97c-0.26,0.3-0.41,0.67-0.44,1.06' />
			<path d='M217.59,45.41c-0.02-0.32-0.15-0.62-0.35-0.86' />
			<path d='M217.09,44.4c-0.24-0.22-0.55-0.36-0.88-0.39' />
			<path d='M215.92,44.01c-0.33,0.03-0.63,0.17-0.88,0.39' />
			<path d='M214.9,44.54c-0.21,0.24-0.33,0.55-0.36,0.87' />
			<path d='M214.55,45.65c0.03,0.32,0.15,0.62,0.36,0.87' />
			<path d='M215.05,46.66c0.24,0.22,0.55,0.35,0.88,0.39' />
			<path d='M216.21,47.05c0.33-0.03,0.64-0.17,0.88-0.39' />
			<path d='M217.24,46.51c0.2-0.24,0.33-0.54,0.35-0.86' />
			<path d='M217.91,45.41c-0.03-0.4-0.18-0.78-0.44-1.09' />
			<path d='M217.33,44.18c-0.31-0.29-0.7-0.46-1.12-0.49' />
			<path d='M215.92,43.68c-0.41,0.03-0.81,0.2-1.11,0.49' />
			<path d='M214.67,44.32c-0.26,0.31-0.42,0.69-0.45,1.09' />
			<path d='M214.22,45.65c0.03,0.4,0.18,0.79,0.45,1.09' />
			<path d='M214.81,46.89c0.31,0.28,0.7,0.45,1.11,0.49' />
			<path d='M216.21,47.37c0.42-0.03,0.81-0.21,1.12-0.49' />
			<path d='M217.47,46.73c0.26-0.3,0.42-0.69,0.44-1.09' />
			<line x1='215.25' y1='57.81' x2='214.49' y2='58.54' />
			<line x1='216.75' y1='56.36' x2='217.36' y2='55.78' />
			<line x1='215.39' y1='57.96' x2='214.78' y2='58.55' />
			<line x1='216.89' y1='56.51' x2='217.64' y2='55.78' />
			<line x1='215.25' y1='56.5' x2='214.49' y2='55.77' />
			<line x1='216.75' y1='57.95' x2='217.36' y2='58.54' />
			<line x1='215.39' y1='56.35' x2='214.78' y2='55.77' />
			<line x1='216.89' y1='57.8' x2='217.64' y2='58.53' />
			<line x1='216.21' y1='55.68' x2='215.92' y2='55.68' />
			<line x1='216.21' y1='58.64' x2='215.92' y2='58.64' />
			<line x1='216.75' y1='56.36' x2='216.89' y2='56.51' />
			<line x1='216.75' y1='57.95' x2='216.89' y2='57.8' />
			<line x1='215.39' y1='56.35' x2='215.25' y2='56.5' />
			<line x1='215.39' y1='57.96' x2='215.25' y2='57.81' />
			<line x1='217.55' y1='57.04' x2='217.55' y2='57.28' />
			<line x1='214.59' y1='57.04' x2='214.59' y2='57.28' />
			<line x1='220.99' y1='57.81' x2='220.24' y2='58.54' />
			<line x1='222.49' y1='56.36' x2='223.1' y2='55.78' />
			<line x1='221.13' y1='57.96' x2='220.52' y2='58.55' />
			<line x1='222.64' y1='56.51' x2='223.39' y2='55.78' />
			<line x1='220.99' y1='56.5' x2='220.24' y2='55.77' />
			<line x1='222.49' y1='57.95' x2='223.1' y2='58.54' />
			<line x1='221.13' y1='56.35' x2='220.52' y2='55.77' />
			<line x1='222.64' y1='57.8' x2='223.39' y2='58.53' />
			<line x1='221.95' y1='55.68' x2='221.67' y2='55.68' />
			<line x1='221.95' y1='58.64' x2='221.67' y2='58.64' />
			<line x1='222.49' y1='56.36' x2='222.64' y2='56.51' />
			<line x1='222.49' y1='57.95' x2='222.64' y2='57.8' />
			<line x1='221.13' y1='56.35' x2='220.99' y2='56.5' />
			<line x1='221.13' y1='57.96' x2='220.99' y2='57.81' />
			<line x1='223.29' y1='57.04' x2='223.29' y2='57.28' />
			<line x1='220.33' y1='57.04' x2='220.33' y2='57.28' />
			<line x1='220.99' y1='46.18' x2='220.24' y2='46.91' />
			<line x1='222.49' y1='44.73' x2='223.1' y2='44.15' />
			<line x1='221.13' y1='46.33' x2='220.52' y2='46.92' />
			<line x1='222.64' y1='44.88' x2='223.39' y2='44.16' />
			<line x1='220.99' y1='44.87' x2='220.24' y2='44.14' />
			<line x1='222.49' y1='46.32' x2='223.1' y2='46.91' />
			<line x1='221.13' y1='44.73' x2='220.52' y2='44.14' />
			<line x1='222.64' y1='46.18' x2='223.39' y2='46.9' />
			<line x1='221.95' y1='44.05' x2='221.67' y2='44.05' />
			<line x1='221.95' y1='47.01' x2='221.67' y2='47.01' />
			<line x1='222.49' y1='44.73' x2='222.64' y2='44.88' />
			<line x1='222.49' y1='46.32' x2='222.64' y2='46.18' />
			<line x1='221.13' y1='44.73' x2='220.99' y2='44.87' />
			<line x1='221.13' y1='46.33' x2='220.99' y2='46.18' />
			<line x1='223.29' y1='45.41' x2='223.29' y2='45.65' />
			<line x1='220.33' y1='45.41' x2='220.33' y2='45.65' />
			<line x1='215.25' y1='46.18' x2='214.49' y2='46.91' />
			<line x1='216.75' y1='44.73' x2='217.36' y2='44.15' />
			<line x1='215.39' y1='46.33' x2='214.78' y2='46.92' />
			<line x1='216.89' y1='44.88' x2='217.64' y2='44.16' />
			<line x1='215.25' y1='44.87' x2='214.49' y2='44.14' />
			<line x1='216.75' y1='46.32' x2='217.36' y2='46.91' />
			<line x1='215.39' y1='44.73' x2='214.78' y2='44.14' />
			<line x1='216.89' y1='46.18' x2='217.64' y2='46.9' />
			<line x1='216.21' y1='44.05' x2='215.92' y2='44.05' />
			<line x1='216.21' y1='47.01' x2='215.92' y2='47.01' />
			<line x1='216.75' y1='44.73' x2='216.89' y2='44.88' />
			<line x1='216.75' y1='46.32' x2='216.89' y2='46.18' />
			<line x1='215.39' y1='44.73' x2='215.25' y2='44.87' />
			<line x1='215.39' y1='46.33' x2='215.25' y2='46.18' />
			<line x1='217.55' y1='45.41' x2='217.55' y2='45.65' />
			<line x1='214.59' y1='45.41' x2='214.59' y2='45.65' />
		</g>
	);
};

export default ThinLineworks;
