import {useSelector} from 'react-redux';
import SCSS from './Caption.module.scss';
import {selectScheme} from '../../../features/panorama/panoramaSlice';

const classnames = require('classnames');

type Props = {
	name: string;
};

const Caption = (props: Props) => {
	const {name} = props;
	const scheme = useSelector(selectScheme);
	const schemeClasses = [
		SCSS.Scheme,
		{
			[SCSS.Scheme1]: scheme === 'scheme1',
			[SCSS.Scheme2]: scheme === 'scheme2',
		},
	];
	return (
		<div className={SCSS.Caption}>
			<div className={SCSS.Inner}>
				<div className={SCSS.UnitLabel}>
					<h1>Unit 04</h1>
					<h3>{name}</h3>
				</div>
				<div className={classnames(schemeClasses)}>
					<h1>{scheme === 'scheme1' ? 'Scheme 1' : 'Scheme 2'}</h1>
				</div>
			</div>
		</div>
	);
};

export default Caption;
